<template>
    <el-dialog
        :title="$t('cars.add_car')"
        :visible.sync="visible"
        width="700px"
        top="40px"
        :close-on-click-modal="false"
        :before-close="closeModal"
    >
        <el-form ref="form" :model="carForm" :rules="rules" class="px-10">
            <el-form-item :label="$t('cars.name')" prop="name">
                <el-input v-model="carForm.name" />
            </el-form-item>
            <el-form-item :label="$t('cars.license_plate')" prop="license_plate">
                <el-input v-model="carForm.license_plate" />
            </el-form-item>
            <el-form-item :label="$t('cars.odometer')" prop="odometer">
                <el-input v-model.number="carForm.odometer" />
            </el-form-item>
            <el-form-item :label="$t('cars.production_year')" prop="production_year">
                <el-input v-model.number="carForm.production_year" />
            </el-form-item>
            <el-form-item :label="$t('cars.holder')">
                <EmployeesDropdown
                    ref="employeesDropdown"
                    withoutQuerySearch
                    customClass="w-full"
                    @selected="employeesFilterChanged"
                />
            </el-form-item>
            <el-form-item :label="$t('cars.note')" prop="note">
                <el-input v-model="carForm.note" type="textarea" :rows="6" resize="none" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('loading.add_car')" @click="add">{{ $t('common.add') }}</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    components: {
        EmployeesDropdown: () => import(/* webpackChunkName: "EmployeesDropdown" */ '@/components/EmployeesDropdown'),
    },

    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedEmployeeUuid: '',
            carForm:              {
                name:            '',
                license_plate:   '',
                odometer:        null,
                production_year: null,
                note:            '',
            },
            rules: {
                name:            { required: true, message: this.$t('common.required'), trigger: 'blur' },
                odometer:        { type: 'number', message: this.$t('common.only_numbers'), trigger: 'blur' },
                production_year: [
                    {
                        type:    'number',
                        min:     1980,
                        max:     new Date().getFullYear(),
                        message: `${this.$t('common.min_1980_and_max')} ${new Date().getFullYear()}`,
                        trigger: 'blur',
                    },
                ],
            },
        };
    },

    methods: {
        employeesFilterChanged(uuid) {
            this.selectedEmployeeUuid = uuid;
        },

        async add() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('loading.add_car');

            try {
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/assets/cars`, {
                    name:            this.carForm.name,
                    license_plate:   this.carForm.license_plate,
                    odometer:        this.carForm.odometer,
                    production_year: this.carForm.production_year,
                    note:            this.carForm.note,
                    holder_uuid:     this.selectedEmployeeUuid,
                });
                this.$notify.success({ title: this.$t('common.success') });
                this.$wait.end('loading.add_car');
                this.$emit('refresh');
                this.closeModal();
            } catch (error) {
                this.$wait.end('loading.add_car');
            }
        },

        closeModal() {
            this.$refs.form.resetFields();
            this.$refs.employeesDropdown.resetSelection();
            this.$emit('close');
        },
    },
};
</script>
